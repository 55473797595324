import React from 'react';
import styled from '@emotion/styled';
import {Link, withRouter} from 'react-router-dom';
import HeaderNav from './HeaderNav';
import {css} from '@emotion/react';
import EleBatManufactureLinkImg from '../assets/ele_bat_manufacture_link.png'

export default withRouter(({location: {pathname}}) => (
    <Navigator>
            <Link to="/greet" style={{textDecoration: 'none'}}>
                <NavContent
                 current={
                    pathname === "/greet" 
                    || pathname === "/business-partner" 
                    || pathname === "/organize-chart" 
                    || pathname === "/way-to-come"}>
                회사소개</NavContent>
            </Link>
            <Link to="/inspection" style={{textDecoration: 'none'}}>
            <NavContent
                 current={
                    pathname === "/inspection" 
                    || pathname === "/license" 
                    || pathname === "/corporation" 
                    || pathname === "/automotive-parts"
                }>
            사업분야</NavContent>
            </Link>
            <Link to="/contact" style={{textDecoration: 'none'}}>
                <NavContent         
                    current={
                        pathname === "/contact"}
                >
                견적의뢰</NavContent>
            </Link>
            <Link to="/notice" style={{textDecoration: 'none'}}>
            <NavContent         
                    current={
                        pathname === "/notice"}
                >커뮤니티</NavContent>
            </Link>
            <Link to={{ pathname: "http://jyo.co.kr/" }} target="_blank" style={{textDecoration: 'none'}}>
            <NavContent>
                <EleBatManufactureLink/>
            </NavContent>
            </Link>
            <HeaderDropBlock>
                <HeaderNav/>
            </HeaderDropBlock> 
            </Navigator>
        )
    );

const EleBatManufactureLink = styled.div`
    width: 15vw;
    max-width: 183px;
    min-width: 174px;
    max-height: 74px;
    min-height: 35px;
    margin-top: -4px;
    padding-left: 40px;
    background-image: url(${EleBatManufactureLinkImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const Navigator = styled.div`
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 1024px) {
    display: none;
    }
    &:hover {
        color: #5d6b83;
        >div {
        display: block;
    }
    }
`;

const NavContent = styled.div`
 width: 15vw;
    padding: 26px 0;
    max-width: 150px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    color: #000;
    font-size: 18px;
    &:hover {
        color: #0A109F;
    }
${props => props.current && css`
    color: #0A109F;
    font-weight: 600;
`}
`;

const HeaderDropBlock = styled.div`
    border-top: 1px solid #D8D8D8;
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
    height: 250px;
    position: fixed;
    background: #fff;
    display: none;
    box-shadow:  0px 3px 6px rgba(0, 0, 0, 0.1);
`;
